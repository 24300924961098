<template>
	<div>
		<van-loading type="circular" color="#AF1237" size="48px" style="position: absolute; top: 40vh;left: 42vw;"
			vertical v-if="error == false">订单生成中</van-loading>
		<van-empty image="error" style="margin-top:20vh;" :description="description" v-if="error == true" />
	</div>

</template>

<script>
	import {
		getOrder
	} from "@/api/api";
	export default {
		name: "getOrder",
		data() {
			return {
				error: false,
				description: ""

			};
		},
		mounted() {},
		methods: {
			getSandCode() {
				document.title = "泽海智能科技";
				var ua = window.navigator.userAgent.toLowerCase();

				var order = this.getUrlParms('orderid');

				if (order == '' || order == null || order == undefined) {
					this.error = true;
					this.description = "订单生成失败,请重新扫码支付";
					return false;
				}

				var paytype = this.getUrlParms('type');

				if (paytype == '' || paytype == null || paytype == undefined) {
					this.error = true;
					this.description = "订单生成失败,请重新扫码支付";
					return false;
				}
				let url = '';
				if (paytype == 'lt') {
					url = ipConfig.ltalipayUrl + "?orderNo=" + order;
				} else if (paytype == 'yl') {
					url = ipConfig.ylalipayUrl + "?orderid=" + order;
				} else {
					this.error = true;
					this.description = "订单生成失败,请重新扫码支付";
					return false;
				}
				// alert(url);
				//进入支付宝支付
				if (navigator.userAgent.indexOf('AlipayClient') > -1) {
					window.location.href = url;
					return false;
				}
				//进入微信支付
				if (ua.match(/MicroMessenger/i) == 'micromessenger') {
					window.location.href = url;
					return false;
				}
				this.error = true;
				this.description = "暂不支持该支付方式";
			},
			getUrlParms: function(name) {
				var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
				var getHref = decodeURI(window.location.search);
				var r = getHref.substr(1).match(reg);
				if (r != null) return unescape(r[2]);
				return null;
			},


		},
		created() {
			this.getSandCode();
		}
	};
</script>

<style>
</style>
