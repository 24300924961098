<template>
	<div>

		<van-image src="https://www.zjzehai.com/code.png" class="img" />

		<div>
			<van-button type="primary">请长按识别二维码进入小程序支付</van-button>
		</div>

	</div>





</template>

<script>
	export default {
		name: "index",
		data() {
			return {};
		},
		mounted() {},
		methods: {


		},
	};
</script>

<style>
	.img {
		width: 200px;
		height: auto;
		margin: 20% auto;
	}
</style>
