import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import errorView from '../views/error.vue'
import wechatView from '../views/wechat.vue'
import getorderView from '../views/getorder.vue'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		component: errorView
	},
	{
		path: '/error',
		name: 'home',
		component: errorView
	},
	{
		path: '/wechat',
		name: 'home',
		component: wechatView
	},
	{
		path: '/getorder',
		name: 'getorder',
		component: getorderView
	},
]

const router = new VueRouter({
	mode: "history",
	routes
})

export default router
