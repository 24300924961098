<template>
	<div>

		<van-empty image="error" style="margin-top:20vh;" description="该订单已完成或被他人支付"  />

	</div>





</template>

<script>
	export default {
		name: "error",
		data() {
			return {};
		},
		mounted() {},
		methods: {
			
			getTitle(){
				document.title="该订单已完成或被他人支付";
			}
		

		},
		created() {
			this.getTitle();
		}
	};
</script>

<style>
	.img {
		width: 200px;
		height: auto;
		margin: 20% auto;
	}
</style>
