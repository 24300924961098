/*
 * @Description: file content
 * @Version: 2.0
 * @Author: ljl
 * @Date: 2022-06-21 09:48:00
 * @LastEditors: ljl
 * @LastEditTime: 2022-07-14 09:31:43
 * @FilePath: \zyypt-ssb-vue_alipay\src\utils\request.js
 */
import axios from 'axios';
import Vue from "vue";
import {
	Toast
} from "vant";
Vue.use(Toast);
const service = axios.create({
	baseURL: ipConfig.baseURL,
	timeout: 5000
});

service.interceptors.request.use(
	config => {
		config.headers['Content-Type'] = 'application/json'
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		let res = response.data
		return res;
	},
	error => {
		console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)

export default service;
